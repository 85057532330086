@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
	.navbarlist li {
		@apply mr-1 md:mr-2 mb-2;
	}

	.nextinfo {
		@apply flex flex-col;
	}
	.nexttitle {
		@apply overflow-hidden h-5 w-12 text-gray-300 text-base break-all;
	}
	.homescreen-section {
		@apply bg-gray-600 text-gray-100 text-2xl rounded-md m-3 pb-3;
		min-height: 240px;
	}
	.homescreen-section > p,
	.homescreen-section > h3,
	.homescreen-section > div {
		@apply m-5 my-2;
	}
	.homescreen-section button,
	.mymodal button {
		@apply bg-yellow-400 text-gray-900 px-3 py-1 rounded my-2 mr-2;
	}
	.homescreen-section button:disabled,
	.mymodal button:disabled {
		@apply bg-gray-700 text-gray-900 px-3 py-1 rounded my-2 mr-2;
	}
	.homescreen-title {
		@apply h-20 text-4xl mb-3 p-5 rounded-t bg-gray-900;
	}
	.song-item {
		@apply flex justify-between font-body w-300 border-b-2 border-yellow-400 mb-1 bg-gray-300 px-5 py-2;
	}
	.song-name:after {
		content: '';
	}
	.song-current:after {
		content: ' *';
		animation: 3s ease infinite fader;
	}
	@keyframes fader {
		0% {
			opacity: 0.1;
		}
		30% {
			opacity: 1;
		}
		100% {
			opacity: 0.1;
		}
	}
	.icon-img {
		@apply mt-5 mr-1 p-2;
	}
	.icon-button {
		@apply rounded bg-yellow-400;
	}
	.icon-button:hover {
		@apply bg-yellow-200;
	}
	.instrument-img {
		height: 30px;
		width: 30px;
		@apply bg-yellow-400;
	}
	.transpose {
		@apply text-yellow-600;
	}
	.setlist-break {
		@apply w-300 mx-auto flex flex-row justify-between py-3 px-3 mb-1 text-gray-100 bg-gray-900 border-yellow-400 border-b-2;
	}
	.setlist-info {
		@apply font-body;
	}
	.setlist-break {
	}
	.setlist-break .newpage {
		display: none;
	}
	.newpage {
		display: none;
	}
	.modal {
		transition: opacity 0.25s ease;
		@apply fixed top-0 left-0 bg-gray-900 bg-opacity-90 w-screen h-screen overflow-scroll;
	}
	.modal-content {
		position: absolute;
		top: 10%;
		left: 50%;
		min-width: 80%;
		transform: translateX(-50%);
		@apply bg-gray-900 px-5;
	}
	button {
		@apply text-gray-200 bg-yellow-600 border-yellow-800 rounded-sm px-3 py-1;
	}
	button.secondary {
		@apply text-gray-800 bg-red-600 border-red-800 rounded-sm px-3 py-1;
	}
	.editlabel {
		@apply w-1/5 font-body text-right mr-3 my-auto;
	}
	.editlabel:after {
		content: ' :';
	}
	.editfield {
		@apply w-4/5 font-hdr text-2xl px-4 py-1 bg-gray-400;
	}
	.instrumentactive {
		@apply bg-yellow-400 !important;
	}
	.svgstroke2 {
		stroke-width: 3;
		stroke: currentColor;
	}
}

@layer utilities {
	body {
		font-family: Antonio, Arial, Helvetica, sans-serif;
		background: #f4f4f4;
	}
	.w-300 {
		width: 96%;
		max-width: 800px;
	}
}

html {
	scroll-behavior: smooth;
}

main {
	color: white;
}
canvas.react-pdf__Page__canvas {
	margin: 1rem auto;
}

.metronome-holder {
	position: absolute;
	z-index: 50;
	right: 0;
}
.options-holder {
	position: absolute;
	z-index: 50;
	left: 1rem;
	top: 8rem;
}
.audio-holder {
	@apply flex justify-center mt-3;
	height: 0;
	visibility: hidden;
	opacity: 0;
	transition: opacity 1s ease, height 1s ease;
}
.show-audio {
	height: auto;
	visibility: visible;
	opacity: 1;
}
.pallette {
	margin: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
}
.palleticon {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.palleticon span {
	font-size: 0.5rem;
}
.pallette > div.selected:after {
	content: '';
	height: 2px;
	background-color: yellow;
	width: 80%;
	display: block;
}

img.overlay {
	position: absolute;
	top: 0;
	overflow: hidden;
}

@media print {
	.navbar {
		display: none !important;
	}
	.song-item {
		margin: 0 !important;
		background: white !important;
		width: 80% !important;
		border: 0;
		font-size: 16pt;
		padding: 2pt 0;
	}
	.setlist-break {
		margin: 0 !important;
		background: white !important;
		color: black !important;
		border: 0;
		font-size: 16pt !important;
	}
	.break__info {
		display: block;
	}
	.break__info span {
		margin-left: 10pt;
	}
	.song.setlist-break.newpage {
		break-after: page;
		/* break-inside: avoid; */
		/* break-after: page; */
	}

	.song_info,
	.song_files,
	.download-button,
	.song-edit-links,
	.newpage {
		display: none !important;
	}

	.song-item:nth-child(5n + 1) {
		margin-bottom: 16pt;
	}
}
