.pulser {
    color: red;
    /* width: 80px;
    height: 80px; */
}
.testingzone {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both; 
  animation: pulsate-fwd 0.5s ease-in-out infinite both;
} 

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(.1);
            transform: scale(.1);
  }
  8% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  80% {
    -webkit-transform: scale(.1);
            transform: scale(.1);
  }
  100% {
    -webkit-transform: scale(.1);
            transform: scale(.1);
  }

  }
  @keyframes pulsate {
    0% {
      -webkit-transform: scale(.1);
              transform: scale(.1);
    }
    8% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
    80% {
      -webkit-transform: scale(.1);
              transform: scale(.1);
    }
    100% {
      -webkit-transform: scale(.1);
              transform: scale(.1);
    }
  }
  